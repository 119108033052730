<template>
  <WeContainer v-if="ready" card="">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0"><i class="far fa-copyright"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="banner.lang" />
            </div>
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <WeImageUploader
        v-model="form.image"
        v-bind:image-required="true"
        ref="imageuploader"
      />

      <!-- Other Features -->
      <div class="font-14">
        <div class="row align-items-center mb-3">
          <div class="col-lg-2 font-weight-bold">
            <label for="banner-title">Başlık :</label>
          </div>
          <div class="col-lg-3">
            <WeInput
              form="input"
              type="text"
              id="banner-title"
              v-model="form.title"
              v-bind:formGroup="false"
            />
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-lg-2 font-weight-bold">
            <label for="banner-description">Açıklama :</label>
          </div>
          <div class="col-lg-3">
            <WeInput
              form="input"
              type="text"
              v-bind:class="{ 'border-danger': $v.form.description.$error }"
              id="banner-description"
              v-model="form.description"
              v-bind:formGroup="false"
            />
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-lg-2 font-weight-bold">
            <label for="banner-url">Link :</label>
          </div>
          <div class="col-lg-3">
            <WeInput
              form="input"
              type="text"
              id="banner-url"
              v-model="form.link"
              v-bind:formGroup="false"
            />
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-lg-2 font-weight-bold">
            <label for="new-tab">Yeni Sekme :</label>
          </div>
          <div class="col-lg-3">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="new-tab"
                v-model="form.new_tab"
              />
              <label class="custom-control-label" for="new-tab"></label>
            </div>
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-lg-2 font-weight-bold">
            <label for="is_active">Durum :</label>
          </div>
          <div class="col-lg-3">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="is_active"
                v-model="form.is_active"
              />
              <label class="custom-control-label" for="is_active"></label>
            </div>
          </div>
        </div>
      </div>
      <!-- Other Features -->
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      errors: [],
      form: {
        image: {
          files: [],
        },
        title: null,
        description: null,
        link: null,
        new_tab: false,
        is_active: true,
      },
      cleanData: {},
      cleanRequest: {},
    };
  },
  components: {
    Navigation,
  },
  validations: {
    form: {
      description: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("banner", ["load", "create", "update", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();
      let imageValid = this.$refs.imageuploader.$refs.single.$v;
      imageValid.$touch();
      if (this.$v.$invalid || imageValid.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.cleanRequest = helper.difference(this.form, this.cleanData);
        this.updateBanner();
      } else {
        this.addBanner();
      }
    },
    addBanner() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Banner listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Detaya Git",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                this.$router.push(`detail/${result.data.id}`);
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateBanner() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Banner listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Sayfada Kal",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                window.location.reload();
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getBannerById() {
      this.ready = false;
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.cleanData = helper.clone(this.form);
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/banners");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
  },
  computed: {
    ...mapState(["shared", "banner"]),
    getTitle() {
      return this.$route.params.id ? "BANNER DÜZENLE" : "BANNER EKLE";
    },
    activePictures() {
      let actives = this.data.pictures.filter((p) => p.active === true);
      return actives.length;
    },
  },
  mounted() {
    this.getBannerById();
  },
  watch: {
    "banner.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getBannerById();
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}
</style>
